import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/investimentos.png'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>Curso de Investimentos</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Curso de Investimentos"
        date = "Fevereiro/2021"
        description = "Curso online de investimentos para pessoas que se identificam como mulheres, compreendendo primeiros passos de investimentos: independência financeira, renda fixa e renda variável. Contou com 20 participantes, tendo duração total de 6h."
      />
      {/* <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={InvistaLogo} alt="" className="partner-logo"/>
                <p className="partner-name">Invista como uma Garota</p>
            </div>
        </div>
      </div> */}
      <MyFooter />
  </div>
)

export default EventsPage
